<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="modal"
      ref="modal"
      role="dialog"
      tabindex="0"
      :aria-hidden="modal"
      class="md:tw-p-4 tw-w-full tw-h-full tw-fixed tw-inset-0 tw-z-[2050] tw-flex tw-flex-col tw-items-start tw-bg-overlay"
      @keydown.esc.stop="hide"
    >
      <!-- m-auto to center the modal on the screen, vertically and horizontally -->
      <div
        aria-label="card"
        :class="[
          maxWidth,
          'tw-m-auto tw-w-full tw-bg-white md:tw-rounded-lg tw-cursor-auto tw-overflow-auto'
        ]"
      >
        <div
          class="tw-sticky tw-top-0 tw-bg-white tw-z-50 md:tw-rounded-t-lg"
        >
          <div class="tw-p-5 tw-flex tw-flex-row tw-justify-between tw-gap-6 tw-items-center tw-border-b tw-border-gray-cc">
            <div class="tw-p-2"></div>
            <h3 aria-label="title" class="tw-text-center tw-flex-grow tw-text-xl">
              {{ title }}
            </h3>
            <button
              aria-label="close"
              type="button"
              class="tw-p-2 tw-text-xl"
              @click="hide"
            >
              <i class="far fa-times" />
            </button>
          </div>
          <slot name="sticky-heading"></slot>
        </div>
        <div aria-label="content" :class="contentPadding">
          <slot><p>This is the default modal content.</p></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    maxWidth: {
      type: String,
      default: 'tw-max-w-xl'
    },
    contentPadding: {
      type: String,
      default: 'tw-py-6 tw-px-8'
    }
  },
  data () {
    return {
      modal: false
    }
  },
  methods: {
    show () {
      this.modal = true
      this.focus()
      this.$emit('show')
    },
    hide () {
      this.modal = false
      this.$emit('hide')
    },
    focus () {
      // To make the esc key work properly, the modal in question should be in focus.
      this.$nextTick(() => {
        this.$refs.modal.focus()
      })
    }
  }
}
</script>
