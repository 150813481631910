import { httpClient } from './axiosClients'

// City Search API
export function fetchCities ({ params }) {
  return httpClient.get('/api/city', { params })
}

export function createCity (payload) {
  return httpClient.post('/api/city', payload)
}

export function getCityById (cityId) {
  return httpClient.get(`/api/city/${cityId}`)
}

export function sendPermanenceEmail (payload) {
  return httpClient.post('/api/send_permanence_email', payload)
}

export function sendEmail (payload) {
  return httpClient.post('/api/send_email', payload)
}

export function checkEmailAttachments (payload) {
  return httpClient.post('/api/check-email-attachments', payload)
}

export function getEmailTemplates () {
  return httpClient.get('/api/email-templates')
}

export function getCommunicationTypes () {
  return httpClient.get('/api/v3/communication-types')
}

export function getPublicationEntityById (publicationId) {
  return httpClient.get(`/api/v3/publications/${publicationId}/entity`)
}

export function createLog (payload) {
  return httpClient.post('/api/logs', payload)
}

export function updateLog (logId, payload) {
  return httpClient.patch(`/api/logs/${logId}`, payload)
}

export function deleteLog (logId) {
  return httpClient.delete(`/api/logs/${logId}`)
}

export function getJobStatus (jobId) {
  return httpClient.get(`/api/jobs/${jobId}`)
}

export function uploadFileToTempStorage (payload) {
  return httpClient.post('/api/temp-file-upload',
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
}

export function getInvoiceContactPrefixTitles () {
  return httpClient.get('/api/invoice-recipient-grouping-titles')
}

export function getApplicationStatus () {
  return httpClient.get('/api/application-status')
}

export function getPaymentTerms () {
  return httpClient.get('/api/payment-terms')
}

export function getSuspensiveConditions () {
  return httpClient.get('/api/suspensive-conditions')
}

export function getProducts () {
  // Products are used for costs
  return httpClient.get('/api/products')
}

export function getFinancialYears () {
  return httpClient.get('/api/financial-years')
}

export function getReasons (payload) {
  const { url = '/api/reasons', params } = payload
  return httpClient.get(url, { params })
}

export function getVetusityConfigRecords () {
  return httpClient.get('/api/vetusity-config-records')
}

export function getLevels (params) {
  return httpClient.get('/api/levels', { params })
}
