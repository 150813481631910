import { gutenborgClient } from './axiosClients'

export function searchTemplates ({ params }) {
  return gutenborgClient.get('/api/templates/search', params)
}

export function getTemplate (templateId) {
  return gutenborgClient.get(`/api/templates/${templateId}`)
}

export function getTemplates (type, brand = null) {
  return gutenborgClient.get('/api/templates', {
    params: { type, brand }
  })
}

export function getCategory (categoryId) {
  return gutenborgClient.get(`/api/categories/${categoryId}`)
}

export function getModules (params) {
  return gutenborgClient.get('/api/modules', { params })
}

export function createDocument (payload) {
  return gutenborgClient.post('/api/generate', payload)
}

export function pollGutenborg (uuid) {
  return gutenborgClient.get(`/api/document/${uuid}`)
}

export function getV2Templates (params) {
  return gutenborgClient.get('/api/v2/templates', { params })
}

export function createV2Document (payload) {
  return gutenborgClient.post('/api/v2/generate', payload)
}

export function pollV2Gutenborg (job_id) {
  return gutenborgClient.get(`/api/v2/job/print/${job_id}/status`)
}

export function generateEstimateReportGrid (payload) {
  return gutenborgClient.post('/api/estimate-report-grid-temp', payload)
}

export function mergePdf (payload) {
  return gutenborgClient.post('/api/merge-pdf', payload)
}
