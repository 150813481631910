/**
 * account.js
 * Account store for IAM.
 * Authors:
 *  Michael Anckaert <michael.anckaert@sinax.be> (26-09-2018)
 */

/**
 * Local store for the Account state. This state contains all user and account related state
 */
import { getUser } from '@/services/user'

/**
 * Initial state of the Account.
 * @type {{user: {}}}
 */
const state = {
  user: null,
  userPromise: {}
}

// Getters
const getters = {
  collaborator: state => {
    return state.user?.collaborator
  }
}

// Actions
const actions = {
  async loadUser ({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('setUserPromise', getUser())
      return state.userPromise
        .then(response => {
          commit('setCurrentUser', response.data)
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
}

// Mutations
const mutations = {
  setCurrentUser (state, user) {
    state.user = user
  },
  setPermissions (state) {
    // @param {Object} collaborator The collaborator we want to add permissions to
    const hasPerm = function (permission, officeReference = '') {
      const officesForPermission = state.user.permissions?.[permission]
      if (!officesForPermission) return false
      return officesForPermission.includes('*') || officesForPermission.includes(officeReference)
    }
    state.user.collaborator.hasPerm = hasPerm
  },

  setUserPromise (state, promise) {
    state.userPromise = promise
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
